<template>
    <section class="section__app">

      <router-view></router-view>

        <!-- <router-view v-slot="{ Component }">
        <transition name="fade">
          <component :is="Component" />
        </transition>
      </router-view> -->
    </section>
</template>

<script>
// import infodiario from './data/diario/infodiario.json'
import infodiarioClubEmelnor from './data/diario/infodiarioClubEmelnor.json'
import infodiarioClubValparaiso from './data/diario/infodiarioClubValparaiso.json'
import infodiarioClubSur from './data/diario/infodiarioClubSur.json'
import infodiarioClubAraucania from './data/diario/infodiarioClubAraucania.json'

export default {
  name: "App",
  data() {
    return {
      infodiario: [...infodiarioClubEmelnor, ...infodiarioClubValparaiso, ...infodiarioClubSur,...infodiarioClubAraucania]
    }
  },
  mounted () {
    
      // Asignar club
      //1.- Emelnor 2.- valparaiso 3.-elSur 4.-Araucania
      let idClub = 4;  
      this.$store.commit('miscellaneous/setSelectClub', idClub)

      if(this.$cookies.get('user-v') && this.$cookies.get('user-v').name != '') {
        this.$store.commit('modal/setSessionActive', true)
        this.$store.commit('modal/setAccessToken', this.$cookies.get('user-v').session)
        this.$store.commit('modal/setNombreUser', this.$cookies.get('user-v').name)
      }
      if(this.getClub.color != '' && this.getClub.color != null) {
        document.documentElement.style.setProperty("--config-color-sitio", this.getClub.color);
      }
      if(this.getClub.color_titulo != '' && this.getClub.color_titulo != null) {
        document.documentElement.style.setProperty("--config-color-titulo", this.getClub.color_titulo);
      }
      if(this.getClub.color_titulo_hover != '' && this.getClub.color_titulo_hover != null) {
        document.documentElement.style.setProperty("--config-color-titulo-hover", this.getClub.color_titulo_hover);
      }
      if(this.getClub.color_publicacion_titulo != '' && this.getClub.color_publicacion_titulo != null) {
        document.documentElement.style.setProperty("--config-color-publicacion-titulo", this.getClub.color_publicacion_titulo);
      }

      this.$store.dispatch('miscellaneous/detectDevice')
    },
   computed: {
    getClub () {
      return this.infodiario.find(item => item.clubId == this.$store.getters['miscellaneous/getSelectClub']) ?? this.infodiario[0];
    }
   }
}
</script>

<style>
 .section__app {
  background: #fff;
    /* min-height: auto;
    @media screen and (min-width: 769px) {
      min-height: 100vh;
    } */
    overflow-x: hidden;
 }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
